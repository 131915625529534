.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.logo-section{
  color: #fff;
  background-color: #ee6e73;
  width: 100%;
  height: 75px;
  line-height: 56px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);;
}
.logo-section h2{
  margin: auto;
}
.h400{
  height: 400px;
}
.size2-5{
  font-size: 2.5rem;
}
.header{
  font-size: 1.92rem;
}

.dataTable{
  display: flex;
  justify-content: space-around;
}
.ml1{
  margin-left: 2px;
}